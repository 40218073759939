import React, { useState, ReactNode, HTMLProps } from "react";
import { StaticImage } from "gatsby-plugin-image";

import Button from "~/components/button";
import {
  AdsModal,
  DomainTypeModal,
  FunctioningLinksModal,
  InformationModalOpener,
  ReferencesModal,
  SpellingErrorsModal,
  WebsiteDateModal
} from "~/components/modals";

interface ContainsModals {
  modalFunctions: ((arg0: boolean) => void)[];
  modalRatings: number[];
  children?: ReactNode;
}

const Nav = (): JSX.Element => {
  return (
    <div className="flex text-3xl mt-7 ml-11 mb-6">
      <Button
        href="/article/lung-health"
        title="Home"
      >
        Home
      </Button>
      <Button
        className="ml-14"
        href="/article/lung-health/contact-us"
        title="Contact Us"
      >
        Contact Us
      </Button>
    </div>
  );
}

const Header = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="flex relative flex-wrap md:flex-nowrap">
      <div className="bg-lung-health-green-800 text-white">
        <h1 className="font-bold text-5xl xl:mt-16 ml-11 leading-loose">American Institute for Lung Health</h1>
        <Nav />
      </div>

      <div className="flex-grow md:text-right text-white p-8 bg-lung-health-green-800">
        <span className="text-3xl ml-8 md:ml-0 mt-8 font-bold relative" style={{ fontFamily: "Asap" }}>
          <InformationModalOpener
            className="absolute left-0 top-0 -ml-12"
            rating={modalRatings[0]}
            onClick={() => modalFunctions[0](true)}
          />
          www.usalung.gov
        </span>
        <div className="whitespace-nowrap mt-16 lg:mt-20 mr-11 text-2xl lg:text-3xl">
          <span>Search&nbsp;&nbsp;</span>
          <input
            className="lg:h-14 lg:w-80 drop-shadow-xl text-gray-800"
            type="text"
          >
          </input>
        </div>

      </div>
    </div>
  );
}

const Footer = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="flex w-full h-28 pt-1 bg-lung-health-green-800 text-white">
      <Nav />
      <InformationModalOpener
        className="ml-8"
        rating={modalRatings[0]}
        onClick={() => modalFunctions[0](true)}
      />
    </div>
  );
}

const ContentArea = ({ children }: HTMLProps<HTMLDivElement>): JSX.Element => {
  return (
    <div className="flex-grow pt-12 pl-16 pr-16 pb-2 bg-lung-health-green-50 text-gray-900">
      {children}
    </div>
  );
}

export const Layout = ({ children }: HTMLProps<HTMLDivElement>): JSX.Element => {
  const [domainTypeModalVisible, setDomainTypeModalVisible] = useState<boolean>(false);
  const [domainTypeModalRating, setDomainTypeModalRating] = useState<number>(-1);
  const [functioningLinksModalVisible, setFunctioningLinksModalVisible] = useState<boolean>(false);
  const [functioningLinksModalRating, setFunctioningLinksModalRating] = useState<number>(-1);

  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Bahnschrift" }}>
      <Header modalFunctions={[setDomainTypeModalVisible]} modalRatings={[domainTypeModalRating]} />
      <ContentArea>
        {children}
      </ContentArea>
      <Footer modalFunctions={[setFunctioningLinksModalVisible]} modalRatings={[functioningLinksModalRating]} />
      {domainTypeModalVisible && (
        <DomainTypeModal
          modalRating={domainTypeModalRating}
          modalVisible={domainTypeModalVisible}
          setModalRating={setDomainTypeModalRating}
          setModalVisible={setDomainTypeModalVisible}
        />
      )}
      {functioningLinksModalVisible && (
        <FunctioningLinksModal
          modalRating={functioningLinksModalRating}
          modalVisible={functioningLinksModalVisible}
          setModalRating={setFunctioningLinksModalRating}
          setModalVisible={setFunctioningLinksModalVisible}
        />
      )}
    </div>
  );
}

const LungHealthHomePage = (): JSX.Element => {
  const [websiteDateModalVisible, setWebsiteDateModalVisible] = useState<boolean>(false);
  const [websiteDateModalRating, setWebsiteDateModalRating] = useState<number>(-1);
  const [referencesModalVisible, setReferencesModalVisible] = useState<boolean>(false);
  const [referencesModalRating, setReferencesModalRating] = useState<number>(-1);
  const [adsModalVisible, setAdsModalVisible] = useState<boolean>(false);
  const [adsModalRating, setAdsModalRating] = useState<number>(-1);
  const [spellingErrorsModalVisible, setSpellingErrorsModalVisible] = useState<boolean>(false);
  const [spellingErrorsModalRating, setSpellingErrorsModalRating] = useState<number>(-1);

  return (
    <>
      <Layout>
        <div className="md:grid md:grid-cols-2 md:gap-16">
          <div className="max-h-md max-w-md justify-self-end">
            <StaticImage
              alt="X-ray of lungs"
              height={428}
              loading="eager"
              placeholder="blurred"
              src="../../../images/lung-health-guy.jpg"
              width={477}
            />
          </div>

          <div className="max-w-lg text-lg leading-tight" style={{ fontFamily: "Century Gothic" }}>
            <h3 className="mt-5 text-4xl md:mt-0" style={{ fontFamily: "Bahnschrift" }}>Overview</h3>
            <p className="mt-5">Asthma is a chronic lung disease in which your airways narrow and produce extra mucus. This makes breathing difficult and can trigger coughing, wheezing, and shortness of breath.</p>
            <InformationModalOpener
              className="3xl:absolute 3xl:-ml-80 3xl:-mt-5 mt-5"
              rating={spellingErrorsModalRating}
              style={{ left: "100%" }}
              onClick={() => setSpellingErrorsModalVisible(true)}
            />
            <p className="mt-5">Childhood asthma affects millions of children and their families. Most children with asthma develop it before age 5. Adults can develop occupational asthma from inhaling fumes, dust, or other harmful substances while on the job. For some people, asthma is a minor nuisance.  For others, it is a serious problem that interferes with daily activities and may even lead to a life-threatening asthma attack.</p>
            <InformationModalOpener
              className="xl:absolute xl:-ml-80 xl:-mt-5 mt-5"
              rating={referencesModalRating}
              style={{ left: "100%" }}
              onClick={() => setReferencesModalVisible(true)}
            />
            <p className="mt-5">Asthma can't be cured, but its symptoms can be controlled. If you have asthma, it's important that you work with your doctor to monitor your symptoms and adjust treatment as needed.</p>
          </div>

          <div className="md:text-right max-w-lg justify-self-end text-lg leading-tight" style={{ fontFamily: "Century Gothic" }}>
            <h3 className="mt-5 text-4xl md:mt-0" style={{ fontFamily: "Bahnschrift" }}>Learn About Asthma</h3>
            <p className="mt-5">Symptoms, Causes, and Risk Factors</p>
            <p>Diagnosis and Treatment</p>
            <p>Living with Asthma</p>
            <p>Current Research References</p>
            <InformationModalOpener
              className="xl:-ml-20 xl:-mt-5 mt-5"
              rating={adsModalRating}
              style={{ left: "100%" }}
              onClick={() => setAdsModalVisible(true)}
            />
          </div>

          <div className="justify-self-start mt-5 max-h-md max-w-md">
            <StaticImage
              alt="X-ray of lungs"
              height={277}
              loading="eager"
              placeholder="blurred"
              src="../../../images/lung-health-lungs.jpg"
              width={184}
            />
          </div>
        </div>
        <div className="flex justify-end" >
          <div className="place-self-end">
            <InformationModalOpener
              className="flex-grow"
              rating={websiteDateModalRating}
              onClick={() => setWebsiteDateModalVisible(true)}
            />
          </div>
          <span className="text-right mt-5 ml-3" style={{ fontFamily: "Century Gothic" }}>
            Last updated on October 23, 2019
          </span>
        </div>
      </Layout>
      {websiteDateModalVisible && (
        <WebsiteDateModal
          modalRating={websiteDateModalRating}
          modalVisible={websiteDateModalVisible}
          setModalRating={setWebsiteDateModalRating}
          setModalVisible={setWebsiteDateModalVisible}
        />
      )}
      {referencesModalVisible && (
        <ReferencesModal
          modalRating={referencesModalRating}
          modalVisible={referencesModalVisible}
          setModalRating={setReferencesModalRating}
          setModalVisible={setReferencesModalVisible}
        />
      )}
      {adsModalVisible && (
        <AdsModal
          modalRating={adsModalRating}
          modalVisible={adsModalVisible}
          setModalRating={setAdsModalRating}
          setModalVisible={setAdsModalVisible}
        />
      )}
      {spellingErrorsModalVisible && (
        <SpellingErrorsModal
          modalRating={spellingErrorsModalRating}
          modalVisible={spellingErrorsModalVisible}
          setModalRating={setSpellingErrorsModalRating}
          setModalVisible={setSpellingErrorsModalVisible}
        />
      )}
    </>
  );
}

export default LungHealthHomePage;

